<template>
  <div id="mian">
    <div class="header">
      <span id="fzcolor">基本信息</span>
      <span @click="toUrl('addcommercial_jsxx_edit', { id: $route.query.id })">结算信息</span>
      <span @click="toUrl('addcommercial_qdxx_edit', { id: $route.query.id })">渠道信息</span>
      <span @click="toUrl('addcommercial_pzxx_edit', { id: $route.query.id })">产品信息</span>
      <span @click="toUrl('addcommercial_pzxx1', { id: $route.query.id })">配置信息</span>
      <span
        v-if="detail.checkStatus == 'PASS'"
        @click="toUrl('addcommercial_dkxq_edit', { id: $route.query.id })"
      >终端信息</span>
    </div>
    <div v-auth="'ACCOUNT:MCH:MCHS:BASE'">
      <div class="desc">
        <h5 class="jsxx">基本信息</h5>
        <div>
          <el-button
            v-auth="'ACCOUNT:MCH:MCHS:BASE/EDIT'"
            @click="toUrl('addcommercial_jbxx_edit', { id: $route.query.id })"
            class="editBt"
            type="primary"
          >编辑</el-button>
          <el-button
            v-auth="'ACCOUNT:MCH:MCHS:BASE/RESENDMAIl'"
            class="reEmailBt"
            type="primary"
            @click="reEmail"
          >重发邮件</el-button>
        </div>
      </div>
      <div class="list clear">
        <ul class="listBox">
          <li>
            <span>商户名称</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.mchName"
                placement="top"
              >
                <span>{{ detail.mchName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>商户简称</span>
            <i class="hidden1">{{ detail.mchShortName }}</i>
          </li>
          <li>
            <span>商户编号</span>
            <i class="hidden1">{{ detail.mchId }}</i>
          </li>
          <li>
            <span>账户编号</span>
            <i class="hidden1">{{ detail.accountId }}</i>
          </li>
          <li>
            <span>所属代理商</span>
            <i class="hidden1">
              {{ detail.agentName ? detail.agentName : "" }}
            </i>
          </li>
          <li>
            <span>商户类型</span>
            <i class="hidden1">{{ mchTypeLists[detail.mchType] }}</i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>商户证件类型</span>
            <i class="hidden1">
              {{ licenceType[detail.licenceType * 1 - 1] }}
            </i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>商户证件编号</span>
            <i class="hidden1">{{ detail.licenceNo }}</i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>联系人</span>
            <i class="hidden1">{{ detail.contactName }}</i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>手机号码</span>
            <i class="hidden1">{{ detail.contactTel }}</i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>邮箱</span>
            <i class="hidden1">{{ detail.contactEmail }}</i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>客服电话</span>
            <i class="hidden1">{{ detail.servicePhone }}</i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>网址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.mchSite"
                placement="top"
              >
                <span>{{ detail.mchSite }}</span>
              </el-tooltip>
            </i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>联系地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.contactAddress"
                placement="top"
              >
                <span>{{ detail.contactAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>法人姓名</span>
            <i class="hidden1">{{ detail.legalPersonName }}</i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>法人身份证号码</span>
            <i class="hidden1">{{ detail.idcardNo }}</i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>备注</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.remark"
                placement="top"
              >
                <span>{{ detail.remark }}</span>
              </el-tooltip>
            </i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>商户证件照片</span>
            <i class="hidden1">
              <a
                v-if="detail.licencePicture"
                href="javascript:;"
                @click="showImg(detail.licencePicture)"
              >点击预览</a>
            </i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>法人身份证正面照片</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardNormalPicture"
                href="javascript:;"
                @click="showImg(detail.idcardNormalPicture)"
              >点击预览</a>
            </i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>法人身份证反面照片</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardReversePicture"
                href="javascript:;"
                @click="showImg(detail.idcardReversePicture)"
              >点击预览</a>
            </i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>开户许可证</span>
            <i class="hidden1">
              <a
                v-if="detail.accountOpeningLicense"
                href="javascript:;"
                @click="showImg(detail.accountOpeningLicense)"
              >点击预览</a>
            </i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>商户协议</span>
            <i class="hidden1">
              <a
                v-if="detail.mchProtocol && detail.mchProtocol.length > 0"
                href="javascript:;"
                @click="showImgs(detail.mchProtocol)"
              >点击预览</a>
            </i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>其他材料</span>
            <i class="hidden1">
              <a
                v-if="detail.otherPicture && detail.otherPicture.length > 0"
                href="javascript:;"
                @click="showImgs(detail.otherPicture)"
              >点击预览</a>
            </i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>创建时间</span>
            <i class="hidden1">{{ detail.createTime }}</i>
          </li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'">
            <span>审核时间</span>
            <i class="hidden1">
              {{ detail.checkTime ? detail.checkTime : "" }}
            </i>
          </li>

          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>经营类型</span>
            <i class="hidden1">{{ microType[detail.microType] }}</i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>店铺地址</span>
            <i class="hidden1">{{ detail.storeAddress }}</i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>店铺名称</span>
            <i class="hidden1">{{ detail.storeName }}</i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>联系人</span>
            <i class="hidden1">{{ detail.contactName }}</i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>手机号码</span>
            <i class="hidden1">{{ detail.contactTel }}</i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>邮箱</span>
            <i class="hidden1">{{ detail.contactEmail }}</i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>客服电话</span>
            <i class="hidden1">{{ detail.servicePhone }}</i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>联系地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.contactAddress"
                placement="top"
              >
                <span>{{ detail.contactAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>联系人身份证号码</span>
            <i class="hidden1">{{ detail.idcardNo }}</i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>备注</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.remark"
                placement="top"
              >
                <span>{{ detail.remark }}</span>
              </el-tooltip>
            </i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>联系人身份证正面照片</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardNormalPicture"
                href="javascript:;"
                @click="showImg(detail.idcardNormalPicture)"
              >点击预览</a>
            </i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>联系人身份证反面照片</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardReversePicture"
                href="javascript:;"
                @click="showImg(detail.idcardReversePicture)"
              >点击预览</a>
            </i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>店铺照片</span>
            <i class="hidden1">
              <a
                v-if="detail.storePicture"
                href="javascript:;"
                @click="showImg(detail.storePicture)"
              >点击预览</a>
            </i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>商户协议</span>
            <i class="hidden1">
              <a
                v-if="detail.mchProtocol && detail.mchProtocol.length > 0"
                href="javascript:;"
                @click="showImgs(detail.mchProtocol)"
              >点击预览</a>
            </i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>创建时间</span>
            <i class="hidden1">{{ detail.createTime }}</i>
          </li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'">
            <span>审核时间</span>
            <i class="hidden1">
              {{ detail.checkTime ? detail.checkTime : "" }}
            </i>
          </li>
          <li>
            <span>最后修改时间</span>
            <i class="hidden1">{{ detail.updateTime }}</i>
          </li>
          <li>
            <span>最后修改人</span>
            <i class="hidden1">{{ detail.lastUpdatedUser }}</i>
          </li>
        </ul>
      </div>
      <div class="status_desc">
        <span>审核状态</span>
        <span class="status">{{ checkStatus[detail.checkStatus] }}</span>
        <el-button
          v-auth="'ACCOUNT:MCH:MCHS:BASE/PASS'"
          @click="auditShop('PASS')"
          v-if="detail.checkStatus != 'PASS'"
          :disabled="detail.checkStatus == 'PASS'"
          class="overBt active"
          type="primary"
        >通过</el-button>
        <el-button
          v-auth="'ACCOUNT:MCH:MCHS:BASE/REJECT'"
          @click="auditShop('REJECT')"
          v-if="detail.checkStatus != 'PASS'"
          :disabled="
            detail.checkStatus == 'REJECT' || detail.checkStatus == 'PASS'
          "
          class="returnBt"
          type="primary"
        >驳回</el-button>
      </div>
      <div
        class="status_desc"
        style="margin-bottom: 200px"
      >
        <span>商户状态</span>
        <span class="status">{{ activateStatus[detail.activateStatus] }}</span>
        <el-button
          v-auth="'ACCOUNT:MCH:MCHS:BASE/ACTIVATE'"
          @click="setShopStatus('ACTIVATE')"
          v-if="detail.checkStatus == 'PASS'"
          class="returnBt"
          :class="detail.activateStatus == 'ACTIVATE' ? 'active' : ''"
          :disabled="detail.activateStatus == 'ACTIVATE'"
          type="primary"
        >激活</el-button>
        <el-button
          v-auth="'ACCOUNT:MCH:MCHS:BASE/CLOSED'"
          @click="setShopStatus('CLOSED')"
          v-if="detail.checkStatus == 'PASS'"
          class="returnBt"
          :class="detail.activateStatus == 'CLOSED' ? 'active' : ''"
          :disabled="detail.activateStatus == 'CLOSED'"
          type="primary"
        >关闭</el-button>
        <el-button
          v-auth="'ACCOUNT:MCH:MCHS:BASE/FROZEN'"
          @click="setShopStatus('FROZEN')"
          v-if="detail.checkStatus == 'PASS'"
          class="returnBt"
          :class="detail.activateStatus == 'FROZEN' ? 'active' : ''"
          :disabled="detail.activateStatus == 'FROZEN'"
          type="primary"
        >冻结</el-button>
      </div>
      <div
        class="cover_view"
        v-if="showImgUrl !== ''"
        @click="showImgUrl = ''"
      >
        <a
          :href="showImgUrl"
          target="_blank"
        >
          <img
            :src="showImgUrl"
            alt="加载失败"
          />
        </a>
      </div>
      <div
        class="cover_view"
        v-if="showImgUrls.length > 0"
        @click="showImgUrls = []"
      >
        <i
          v-if="showImgUrlIndex > 0"
          class="el-icon-arrow-left left"
          @click.stop="showImgUrlIndex--"
        ></i>
        <i
          v-if="showImgUrlIndex < showImgUrls.length - 1"
          class="el-icon-arrow-right right"
          @click.stop="showImgUrlIndex++"
        ></i>
        <a
          :href="showImgUrls[showImgUrlIndex].url"
          target="_blank"
        >
          <img
            :src="showImgUrls[showImgUrlIndex].url"
            alt="加载失败"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {
  shopDetail,
  auditShop,
  setShopStatus,
  resend,
} from "@/api/user/shopManager.js";
export default {
  data() {
    return {
      detail: {},
      mchTypeLists: {
        "": "",
        ENTERPRISE: "企业",
        INDIVIDUAL_BUSINESS: "个体工商户",
        MICRO_ENTERPRISE: "小微商户",
        PUBLIC_INSTITUTION: "事业单位",
        PRIVATE_NONENTERPRISE_UNIT: "民办非企业单位",
        SOCIAL_ORGANIZATION: "社会团体",
      },
      licenceType: [
        "统一社会信用代码证书",
        "有偿服务许可证（军队医院适用）",
        "医疗机构执业许可证（军队医院适用）",
        "企业营业执照（挂靠企业的党组织适用）",
        "组织机构代码证（政府机关适用）",
        "社会团体法人登记证书",
        "民办非企业单位登记证书",
        "基金会法人登记证书",
        "慈善组织公开募捐资格证书",
        "农民专业合作社法人营业执照",
        "宗教活动场所登记证",
        "营业执照",
        "营业执照号码（多合一）",
        "事业单位法人证书",
        "其他证书/批文/证明",
      ],
      microType: {
        MICRO_TYPE_STORE: "店铺场所",
        MICRO_TYPE_MOBILE: "流动经营/便民服务",
        MICRO_TYPE_ONLINE: "线上商品/服务交易",
      },
      checkStatus: {
        WAIT: "待审核",
        PASS: "已通过",
        REJECT: "已驳回",
      },
      activateStatus: {
        NOTACTIVATE: "未激活",
        ACTIVATE: "已激活",
        FROZEN: "已冻结",
        CLOSED: "已关闭",
      },
      showImgUrl: "",
      showImgUrls: [], //查看多个图片
      showImgUrlIndex: 0, //查看多个图片切换
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      shopDetail(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.detail = res.resultData.mchDetail;
          // console.log("商户详情信息",this.detail)
        }
      });
    },
    // 浏览单个图片
    showImg(val) {
      this.showImgUrl = val;
    },
    // 浏览多个图片
    showImgs(val) {
      this.showImgUrls = val ? JSON.parse(val) : [];
      this.showImgUrlIndex = 0;
    },
    // 审核商户
    auditShop(val) {
      auditShop(this.$route.query.id, {
        checkStatus: val,
      }).then((res) => {
        if (res) {
          if (res.resultStatus == true) {
            this.$message.success("审核成功");
          }
          this.getDetail();
        }
      });
    },
    // 激活/冻结商户
    setShopStatus(val) {
      this.$confirm("此操作将修改状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setShopStatus(this.$route.query.id, {
            activateStatus: val,
          }).then((res) => {
            if (res.resultStatus) {
              switch (val) {
                case "ACTIVATE":
                  this.$message.success("激活成功");
                  break;
                case "FROZEN":
                  this.$message.success("冻结成功");
                  break;
                case "CLOSED":
                  this.$message.success("关闭成功");
                  break;
              }
              this.getDetail();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 重发邮件
    reEmail() {
      resend(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.$message.success("重发邮件请求成功");
        }
      });
    },
    // 跳转页面
    toUrl(name, query) {
      console.log("name", name);
      console.log("query", query);

      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
@import "../../../assets/css/detail.css";
.tab1 {
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}
.status_desc {
  height: 32px;
  margin: 0 10px 20px 15px;
  padding: 10px 30px 10px 35px;
  background-color: #f4f5f6;
  font-size: 16px;
  line-height: 32px;
  color: #999999;
}

.status_desc .status {
  margin-left: 56px;
  color: #333333;
}

.status_desc .overBt {
  float: right;
  width: 60px;
  height: 32px;
  margin-left: 10px;
  padding: 0;
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
  border-radius: 3px;
}

.status_desc .overBt.active {
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
}

.status_desc .returnBt {
  float: right;
  width: 60px;
  height: 32px;
  padding: 0;
  margin-left: 10px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 3px;
  font-size: 12px;
  color: #333333;
}

.status_desc .returnBt.active {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
}
</style>
